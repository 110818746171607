<template>
	<div>
		<!-- 公共头部 -->
<Header></Header>
		
		<div class="section2">
			<div class="box">
				<div class="cate-title">报名信息</div>
				<div class="form-box">
					<div class="item">
						<div class="title">姓名</div>
						<div class="right-box">
							<font>{{orderInfo.name}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">性别</div>
						<div class="right-box">
							<font>{{orderInfo.sex}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">身份证号</div>
						<div class="right-box">
							<font>{{orderInfo.cardid}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">学历</div>
						<div class="right-box">
							<font>{{orderInfo.edu}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">单位</div>
						<div class="right-box">
							<font>{{orderInfo.company}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">联系方式</div>
						<div class="right-box">
							<font>{{orderInfo.tel}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">微信号</div>
						<div class="right-box">
							<font>{{orderInfo.weixin}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">邮箱</div>
						<div class="right-box">
							<font>{{orderInfo.email}}</font>
						</div>
					</div>
					
					<div class="item">
						<div class="title">工种</div>
						<div class="right-box">
							<font>{{orderInfo.subject}}</font>
						</div>
					</div>
					<div class="item" v-if="orderInfo.dengji">
						<div class="title">技能等级</div>
						<div class="right-box">
							<font>{{orderInfo.dengji}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">领证类型</div>
						<div class="right-box">
							<font>{{orderInfo.type}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">报名途径</div>
						<div class="right-box">
							<font>{{orderInfo.basics}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">证件有效期</div>
						<div class="right-box">
							<font>{{orderInfo.yxq}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">区域</div>
						<div class="right-box">
							<font>{{orderInfo.school}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">缴费方式</div>
						<div class="right-box">
							<font>{{orderInfo.pay_type}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">费用</div>
						<div class="right-box">
							<font color="#ff2e2e">¥{{orderInfo.money}}</font>
						</div>
					</div>
				</div>
			</div>
			
			<div class="box">
				<div class="cate-title">支付方式</div>
				<div class="list">
					<div class="item" v-for="(item,index) in payList" @click="curIndex = index" :key="index">
						<div class="title" :style="'background-image: url(' + item.icon + ')'">{{item.title}}</div>
						<img class="icon" :src="curIndex == index ? require('@/assets/images/radio_checked.png') : require('@/assets/images/radio_uncheck.png')" />
					</div>
				</div>
			</div>
			
			<div class="submit-box">
				<div v-if="!isWx" class="submit" @click="submit">确认支付</div>
				<div v-if="isWx" class="submit" @click="ToPay">确认支付</div>
			</div>
			
		</div>
		
		<div v-html="alipayWap" ref="alipayWap"></div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '线上缴费',
				orderInfo: '',
				curIndex: 0,
				order:'',
				timer: '',//定时器名字
				alipayWap: '',
				isWx:false,
			}
		},
		computed: {
			uid() {
				
					return this.$route.query.uid
			},
			payList() {
				var payList = [];
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					payList = [
						{
							title: '微信支付',
							id: 2,
							icon: require('@/assets/images/icon30.png')
						}
					]
				} else {
					payList = [
						{
							title: '支付宝支付',
							id: 1,
							icon: require('@/assets/images/icon29.png')
						},
						{
							title: '微信支付',
							id: 2,
							icon: require('@/assets/images/icon30.png')
						}
					];
				}
				return payList;
			}
		},
		activated() {
			
			this.getUserInfo();
			
		},
		mounted() {
			   let wxJs = document.createElement("script");
    wxJs.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
    document.head.appendChild(wxJs);
			this.getEnv();
		},
		deactivated() {
			this.orderInfo = '';
			this.curIndex = 0;
			clearInterval(this.timer);
		},
		methods: {
				ToPay(e){
		console.log(e);
		let order = this.order;
		let oid = this.orderInfo.id;
		  wx.miniProgram.getEnv((res) => {
          if (res) {
		wx.miniProgram.navigateTo({url:'/pages/pay/pay?order='+order+'&oid='+oid+'&userid='+this.uid})
          } else {
         
		
          }
        });
	
	
	},
			  // 获取环境
    getEnv() {
      var ua = navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
		 
        wx.miniProgram.getEnv((res) => {
		
          if (res.miniprogram) {
			  this.isWx = true;
       	  } else {
           this.isWx = false;
		 }
        });
      } else {
        this.isWx = false;
      }

    },
			// 轮询订单支付状态
			async updateOrder() {
				console.log(this.$route);
				var uid = this.uid;
			
				var order = this.$route.query.order;
				var data = {
					uid, order
				}
				const { data:res } = await this.$http.get('/order/order', { params: data });
				if(this.$store.state.beta){
					console.log('轮询订单支付状态：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var pay_status = res.data.pay_status;
					if(pay_status == 1){
						this.$router.replace({path:'/pay_status',query:{ order }});
					}
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			// 提交支付
			async submit() {
				var oid = this.$route.query.order;
				var data = {
					oid
				}
				this.$toast.loading('加载中...');
				var typeId = this.payList[this.curIndex].id;
				if(typeId == 1){
					data['is_mobile'] = 1;
					const { data:res } = await this.$http.get('/pay/zfb', { params: data });
					this.$toast.close();
					if(this.$store.state.beta){
						console.log('个人报名 支付宝支付：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
					}
					this.alipayWap = res;
					this.$nextTick(() => {
						this.$refs.alipayWap.children[0].submit()
					});
					var that = this;
					that.timer = setInterval(function () {
						that.updateOrder();
					}, 1000);
				}
				if(typeId == 2){
					data['is_mobile'] = 1;
					var ua = navigator.userAgent.toLowerCase();
					if (ua.match(/MicroMessenger/i) == "micromessenger") {
						data['is_weixin'] = 1;
						data['openid'] = window.localStorage.getItem('openid');
						if(!data['openid']){
							this.$messageBox.alert('未获取到用户openid，请重新进入系统！', '温馨提示');
							return false;
						}
					} else {
						data['is_weixin'] = 0;
					}
					const { data:res } = await this.$http.get('/pay/wxpay', { params: data });
					this.$toast.close();
					if(this.$store.state.beta){
						console.log('个人报名 微信支付：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
					}
					var code = res.code;
					if(code == 1) {
						if(data['is_weixin'] == 1){
							this.callpay(res.data,oid);
						} else {
							window.location.href = res.data.mweb_url;
							var that = this;
							that.timer = setInterval(function () {
								that.updateOrder();
							}, 1000);
						}
					} else {
						this.$messageBox.alert(res.data, '温馨提示');
					}
				}
			},
			callpay(jsApiParameters,order){
				if (typeof WeixinJSBridge == "undefined"){
					if( document.addEventListener ){
						document.addEventListener('WeixinJSBridgeReady', this.jsApiCall, false);
					} else if (document.attachEvent){
						document.attachEvent('WeixinJSBridgeReady', this.jsApiCall); 
						document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall);
					}
					this.$messageBox.alert('请在微信浏览器中执行！', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.replace({path:'/pay_status',query:{ order }});
						}
					});
				}else{
					this.jsApiCall(jsApiParameters,order);
				}
			},
			jsApiCall(jsApiParameters,order){
				
				var that = this
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest',
					JSON.parse(jsApiParameters),
					function(res){
						
						WeixinJSBridge.log(res.err_msg);
						
						if (res.err_msg == 'get_brand_wcpay_request:ok') {
							
							//支付成功后跳转的地址
							that.$router.replace({path:'/pay_status',query:{ order }});
						} else  {
							
							that.$router.replace({path:'/pay_status',query:{ order }});
							
						}
					}
				);
			},
			// 获取订单详情
			async getOrderInfo() {
				
				var uid = this.uid;
				console.log(this.$route);
				var order = this.$route.query.order;
				this.order = order;
				var data = {
					uid, order
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/order/order', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('个人报名 订单详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.orderInfo = res.data;
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			async getUserInfo(){
			
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getOrderInfo();
				}
			},
		},
	};
</script>

<style scoped>
	.section2{
		padding-top: 0.3rem;
		padding-bottom: 0.6rem;
	}
	.section2 .box{
		margin-top: 0.3rem;
	}
	.section2 .box:first-child{
		margin-top: 0;
	}
	.section2 .box .cate-title{
		height: 0.92rem;
		font-size: 0.32rem;
		color: #333333;
		display: flex;
		align-items: center;
		margin: 0 0.3rem;
		padding-left: 0.16rem;
		position: relative;
	}
	.section2 .box .cate-title::before{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.05rem;
		height: 0.26rem;
		background: #ff6000;
		left: 0;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.section2 .box .form-box{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section2 .box .form-box .item{
		padding: 0 0.3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1rem;
		border-top: 0.02rem solid #e1e1e1;
	}
	.section2 .box .form-box .item:first-child{
		border-top: none;
	}
	.section2 .box .form-box .item .title{
		font-size: 0.28rem;
		color: #333333;
	}
	.section2 .box .form-box .item .right-box{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 5rem;
	}
	.section2 .submit-box{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.6rem;
	}
	.section2 .submit-box .submit{
		width: 5.8rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #0372c7;
		font-size: 0.3rem;
		color: #ffffff;
		border-radius: 0.5rem;
	}
	.section2 .box .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section2 .box .list .item{
		padding: 0 0.3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1rem;
		border-top: 0.02rem solid #e1e1e1;
	}
	.section2 .box .list .item:first-child{
		border-top: none;
	}
	.section2 .box .list .item .title{
		font-size: 0.28rem;
		display: flex;
		align-items: center;
		height: 0.5rem;
		color: #333333;
		padding-left: 0.68rem;
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 0.48rem 0.48rem;
	}
	.section2 .box .list .item .icon{
		width: 0.3rem;
		height: 0.3rem;
	}
</style>